<template>
  <v-container style="max-width: 1200px;">
    <h1 class="display-1">Update Log</h1>
    <div class="mt-3 mb-3">
      We take pride in our ability to innovate.  View all the recent updates below.
    </div>
    <v-timeline :dense="$store.state.isMobile">
      <v-timeline-item v-for="update of updates" :key="update.title">
        <v-card outlined @click.stop="open(update.url)" hover>
          <v-card-title class="headline centered">
            {{update.title}}
          </v-card-title>
          <v-card-subtitle class="caption font-italic centered">
            {{update.date.toDateString()}}
          </v-card-subtitle>
          <v-card-text class="mt-3">
            <div>{{update.details}}</div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
  export default {
    name: "UpdateLog.vue",
    methods: {
      open(url) {
        if(url) {
          window.open(url, '_blank')
        }
      }
    },
    data () {
      let updates = [
        // {
        //   title: 'Data Access Limits',
        //   details: 'You can now limit access to data at a granular level.  For example, you could limit report recipients to only viewing high risk data, or only screenshots, or only the websites they visit (like traditional accountability software), and more.',
        //   url: `https://articles/reports/change-data-access-limits`,
        //   date: new Date('2020/04/10')
        // },
        // {
        //   title: 'Report Recipients View Updated',
        //   details: 'Report recipients can now view the same information as account owners, including real time updates to screenshots & events, list of websites visited, screen and app time usage, etc.  They also no longer need to click on the emailed link to view the screenshots--once the link has been clicked once they just need to login and they\'ll have access to all the information.',
        //   url: false,
        //   date: new Date('2020/03/24')
        // }
      ]

      updates.sort((a, b) => {
        return b.date.getTime() - a.date.getTime()
      })

      return {
        updates: updates
      }
    }
  }
</script>

<style scoped>

</style>