var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "max-width": "1200px" } },
    [
      _c("h1", { staticClass: "display-1" }, [_vm._v("Update Log")]),
      _c("div", { staticClass: "mt-3 mb-3" }, [
        _vm._v(
          "\n    We take pride in our ability to innovate.  View all the recent updates below.\n  "
        )
      ]),
      _c(
        "v-timeline",
        { attrs: { dense: _vm.$store.state.isMobile } },
        _vm._l(_vm.updates, function(update) {
          return _c(
            "v-timeline-item",
            { key: update.title },
            [
              _c(
                "v-card",
                {
                  attrs: { outlined: "", hover: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.open(update.url)
                    }
                  }
                },
                [
                  _c("v-card-title", { staticClass: "headline centered" }, [
                    _vm._v("\n          " + _vm._s(update.title) + "\n        ")
                  ]),
                  _c(
                    "v-card-subtitle",
                    { staticClass: "caption font-italic centered" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(update.date.toDateString()) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-card-text", { staticClass: "mt-3" }, [
                    _c("div", [_vm._v(_vm._s(update.details))])
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }